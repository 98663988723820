const allCategories = [
  {
    subheader: 'contacts',
    icon: 'person',
    showAvatarSymbols: true,
    options: [
      {
        name: 'Dianne Russell',
        description: 'amaranth@outlook.com',
        avatar: 'https://randomuser.me/api/portraits/men/18.jpg',
      },
      {
        name: 'Arlene McCoy',
        description: 'hwestiii@mac.com',
        avatar: 'https://randomuser.me/api/portraits/women/8.jpg',
      },
      {
        name: 'Jane Cooper',
        description: 'ewaters@comcast.net',
        color: 'orange',
      },
      {
        name: 'Savannah Nguyen',
        description: 'grolschie@mac.com',
        color: 'purple',
      },
    ],
  },
  {
    subheader: 'organisations',
    icon: 'domain',
    options: [
      {
        name: 'Hewlitt Ltd.',
        description: 'hewlitt.com',
        avatar: require('@/assets/images/logos/ibm.svg'),
      },
      {
        name: 'Basement audit',
        description: 'base-audit.org',
        color: 'crimson',
      },
    ],
  },
  {
    subheader: 'tasks',
    icon: 'assignment_turned_in',
    options: [
      {
        name: 'Create slide deck/new pricing',
        description: 'Client retention',
        color: 'red',
      },
    ],
  },
  {
    subheader: 'deals',
    icon: 'tmi-sale',
    options: [
      {
        name: 'Microsoft deal',
        description: 'Sales pipeline',
        color: 'orange',
      },
    ],
  },
  {
    subheader: 'tickets',
    icon: 'email',
    options: [
      {
        name: 'Can’t connect payPal',
        description: 'Jaquelin James',
        color: 'mint',
      },
    ],
  },
  {
    subheader: 'chats',
    icon: 'question_answer',
    options: [
      {
        name: 'Help me with signup',
        description: 'William Smith',
        color: 'blue',
      },
    ],
  },
  {
    subheader: 'campaigns',
    icon: 'tmi-campaign',
    options: [
      {
        name: 'Newsletter - May 2022',
        description: 'Email campaign',
        color: 'green',
      },
    ],
  },
  {
    subheader: 'lists',
    icon: 'tmi-people',
    options: [
      {
        name: 'Promotion clients (Aug, 2021)',
        description: '45 contacts',
        color: 'crimson',
      },
      {
        name: 'First recontact list',
        description: '40 contacts',
        color: 'crimson',
      },
      {
        name: 'Friday reparty',
        description: '144 contacts',
        color: 'crimson',
      },
      {
        name: 'Summer resale',
        description: '280 contacts',
        color: 'crimson',
      },
    ],
  },
  {
    subheader: 'segments',
    icon: 'pie_chart',
    options: [
      {
        name: 'Perspective',
        description: '78 contacts',
        color: 'mint',
      },
      {
        name: 'Autumn sale',
        description: '78 contacts',
        color: 'mint',
      },
      {
        name: 'Summer',
        description: '78 contacts',
        color: 'mint',
      },
      {
        name: 'A Guide',
        description: '78 contacts',
        color: 'mint',
      },
      {
        name: 'Compress',
        description: '78 contacts',
        color: 'mint',
      },
      {
        name: 'Methodologies',
        description: '78 contacts',
        color: 'mint',
      },
    ],
  },
]

export type AssociateOption = {
  name: string;
  description: string;
  icon: string;
  avatar?: string;
  color?: string;
  counter?: string;
}

type AssociateSubheader = {
  value: string;
  text: string;
  subheader: boolean;
  notForSearch: boolean;
  name?: string;
}

const standardCategories = ['contacts', 'organisations', 'tasks', 'tickets', 'chats', 'campaigns']

export const getAssociateOptions = (specialCategories = standardCategories, hideSubheader = false): (AssociateOption | AssociateSubheader)[] => {
  const resultArr = [] as (AssociateOption | AssociateSubheader)[]
  allCategories.forEach(category => {
    // add standard or special categories
    if (specialCategories.length && !specialCategories.includes(category.subheader)) return
    // add icon to each option
    const categoryOptions = category.options.map(option => ({
      ...option,
      icon: category.icon,
      showAvatarSymbols: category.showAvatarSymbols,
    })) as (AssociateOption | AssociateSubheader)[]

    // add title of category to options
    if (!hideSubheader) {
      categoryOptions.unshift({
        value: category.subheader,
        text: category.subheader,
        subheader: true,
        notForSearch: true,
      })
    }
    resultArr.push(...categoryOptions)
  })
  return resultArr
}
