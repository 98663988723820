
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { getAssociateOptions } from '@/mock/associateCategories'

export default defineComponent({
  components: {
    TmModal,
    TmFormLine,
    TmButton,
  },
  setup() {
    const pin = ref()
    const options = getAssociateOptions(['contacts', 'lists', 'segments'])

    return {
      pin,
      options,
    }
  },
})
